<template>
   <v-container fluid>
      <!--Title & Description -->
      <v-row class="pt-12">
         <v-col cols="12" class="pa-0 p-relative d-flex flex-column align-center justify-center">
            <div class="pl-5">
               <v-img :src="assets.createSchedule.editScheduleIcon" width="100" contain />
            </div>

            <h1>Update your Schedule</h1>
         </v-col>
      </v-row>

     <!--  <v-row class="py-5 align-center justify-center">
         <v-col cols="11" md="5" class="pa-4 d-flex align-center justify-center">
            <p class="mx-auto text-center font-weight-medium text-h6 text-sm-h5">
               Customize your own Visual Schedules to save, print or use in their digital format. Enjoy the
               ever-increasing library of beautiful images and easily upload your own for that personalized touch.
            </p>
         </v-col>
      </v-row> -->

      <!--Icon Buttons-->
     <!--  <v-row class="d-flex align-center justify-center">
         <v-col cols="12" class="d-flex align-center justify-center">
            <div
               class="ma-0 pa-6 d-flex flex-column align-center justify-center"
               v-for="icon in icons"
               :key="icon.title">
               <div
                  class="pa-5 icon-background-active"
                  :class="[icon.title == active ? 'icon-background-active' : 'icon-background']"
                  @click="active = icon.title">
                  <v-icon v-if="icon.icon" contain size="40" @click="iconClick(icon)" color="schedules">{{
                     icon.icon
                  }}</v-icon>
               </div>
               <div class="icon-text text-center font-weight-bold mt-4" v-html="icon.html"></div>
            </div>
         </v-col>
      </v-row> -->

      <!--Schedule Maker-->
      <v-row class="py-8 align-center justify-center">
         <v-col cols="12" class="pa-0 d-flex align-center justify-center">
            <schedule-image
               ref="scheduleImageRef"
               :yourSchedule="yourSchedule"
               :scheduleTitle="scheduleTitle"
               :scheduleId="scheduleId"></schedule-image>
         </v-col>
      </v-row>
   </v-container>
</template>

<script>
import scheduleImage from "@/components/schedule/schedule-images.vue";
import { mapState } from "vuex";
export default {
   components: { scheduleImage },
   data() {
      return {
         active: "Add All Schedules",
         yourSchedule: [],
         scheduleTitle: "",
         scheduleId: "",
      };
   },
   methods: {
      //icons view all schedules or upload a photo
      async iconClick(icon) {
         if (icon?.route) {
            this.$router.push(icon.route);
         }

         if (icon?.photoDialog) {
            this.$refs.scheduleImageRef.openViewImagesDialog();
         }
      },

      getImage(item) {
         if (item && item._id) if (!item?._id) return;
         return `${this.$fluro.asset.imageUrl(item._id)}&noRedirect=true`;
      },
      // async open(schedule) {
      //    this.loading = true;
      //    this.schedule = schedule;
      //    console.log("schedule", schedule);
      //    this.dialog = true;
      //    let list = schedule?.data?.scheduleList || [];

      //    this.imageArray = await Promise.all(
      //       list.map(async (item) => {
      //          let formettedItem = {
      //             ...item,
      //             id: item._id,
      //             imgSrc: await this.getImage(item),
      //          };
      //          return formettedItem;
      //       })
      //    );
      //    console.log("image array", this.imageArray);
      //    this.logoImage = await this.logoImg();

      //    console.warn("OPEN (scheduleTitleHeight):", this.scheduleTitleHeight);

      //    this.loading = false;
      // },
   },
   computed: {
      ...mapState(["assets"]),
      icons() {
         return [
            {
               title: "Add All Schedules",
               icon: "mdi-calendar-plus",
               html: "View All <br/> Schedules",
               route: "/schedules",
            },
            {
               title: "View Uploaded Photos",
               icon: "mdi-image-multiple",
               html: "View Uploaded <br/> Photos",
               photoDialog: true,
            },
         ];
      },
   },
   async mounted() {
      console.warn("PARAMS:", this.$route.params);
      const id = this.$route.params.id;

      let yourSchedule = await this.$fluro.api
         //get the basic set of images
         .get(`/content/scheduleUser/${id}`)
         .then(({ data }) => {
            console.log("data in edit", data);
            return data;
         })
         .catch((err) => console.log(err));

      this.scheduleTitle = yourSchedule?.title;
      this.yourSchedule = yourSchedule?.data?.scheduleList;
      this.scheduleId = yourSchedule._id;

      this.yourSchedule = this.yourSchedule.map((item) => ({
         ...item,
         imgSrc: this.getImage(item),
      }));
      console.log("updated schedule with new image url", this.yourSchedule);

      console.warn("getting image url on edit schedule", this.getImage(this.yourSchedule[0]));
      console.warn("schedule data:", this.scheduleId, this.yourSchedule);
   },
};
</script>
<!-- <style lang="scss">
.icons-container-flex {
   column-gap: 15px;
   flex-wrap: wrap;
}

.icon-background {
   background-color: #e8e5e5;
   border-radius: 50%;
   flex: 0 0 auto;
}
.icon-background-active {
   background-color: white;
   border-radius: 50%;
   flex: 0 0 auto;
}
.icon-text {
   display: -webkit-box;
   -webkit-line-clamp: 2;
   -webkit-box-orient: vertical;
   overflow: hidden;
   text-overflow: ellipsis;
}
</style> -->
